import React from 'react';
import MachineLearning from '@page-components/open-source/SubPages/MachineLearning';
import OpenSourcePageWrapper from '@page-components/open-source/OpenSourcePageWrapper';

const OpenSourcePage = () => {
  return (
    <OpenSourcePageWrapper>
      <MachineLearning />
    </OpenSourcePageWrapper>
  );
};

export default OpenSourcePage;
