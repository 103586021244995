import './MachineLearning.scss';
import React from 'react';
import links from '@constants/links';
import Title from '../components/Title';
import Card from '../components/Card';
import Buttons from '../components/Card/components/Buttons';
import GithubIcon from '../../assets/github.svg';
import TensorflowIcon from './assets/tensorflow.svg';
import KerasIcon from './assets/keras.svg';
import PytorchIcon from './assets/pytorch.svg';
import ScikitIcon from './assets/scikit.svg';
import MariaDBIcon from './assets/mariadb.svg';
import PrestoIcon from './assets/presto.svg';
import TrinoIcon from './assets/trino.svg';
import TableauIcon from './assets/tableau.svg';
import PandasIcon from './assets/pandas.svg';
import NumpyIcon from './assets/numpy.svg';
import RIcon from './assets/r.svg';
import ArrowIcon from './assets/arrow.svg';
import PythonIcon from './assets/python.svg';
import BIBackground from './assets/texture_analytics_zisualizations.png';
import InteroperabilityBackground from './assets/texture_interoperability.png';

const MachineLearning: React.FC = () => {
  return (
    <div className="TDB-OpenSourcePage__MachineLearning">
      <Title>ML & Data Science</Title>
      <Card
        title="TileDB-ML"
        description="Store ML models alongside direct access to your multi-modal datasets for training and prediction. Support for TensorFlow-Keras, PyTorch and scikit-learn."
        gradientColor="linear-gradient(223.02deg, #0058F8 14.33%, #A30EFF 94.14%)"
      >
        <div className="TDB-OpenSourcePage__MachineLearning__ml__icons">
          <img src={TensorflowIcon} alt="tensorflow" />
          <img src={KerasIcon} alt="keras" />
          <img src={PytorchIcon} alt="pytorch" />
          <img src={ScikitIcon} alt="scikit" />
        </div>
        <Buttons items={[{ text: 'View on Github', icon: GithubIcon, link: links.github.tiledbML }]} />
      </Card>
      <Card
        gradientColor="linear-gradient(90deg, #785DDF -0.79%, #EF787D 100%)"
        title="SQL"
        description="Access TileDB arrays using a range of SQL engines, including MariaDB, Presto, and Trino."
      >
        <Buttons
          items={[
            { text: 'MariaDB', icon: MariaDBIcon, link: links.github.tiledbMariaDB },
            { text: 'Presto', icon: PrestoIcon, link: links.github.tiledbPresto },
            { text: 'Trino', icon: TrinoIcon, link: links.github.tiledbTrino },
          ]}
        />
      </Card>

      <Card
        gradientColor="#0BDEFB"
        title="BI and Analytics"
        description="Connect to traditional enterprise BI and analytics tools using the type 4 JDBC driver for TileDB Cloud, and power visualizations using the TileDB-Tableau-Connector. Directly support serverless SQL queries within Python on TileDB Cloud using the Python DB API."
        backgroundImage={BIBackground}
      >
        <Buttons
          items={[
            { text: 'JDBC', icon: GithubIcon, link: links.github.tiledbJDBC },
            { text: 'Tableau Connector', icon: TableauIcon, link: links.github.tiledbTableauConnector },
            { text: 'Python DB API', icon: PythonIcon, link: links.github.tiledbPythonDBAPI },
          ]}
        />
      </Card>

      <Card
        gradientColor="#0BDEFB"
        title="Interoperability"
        description="In addition to TileDB-maintained integrations, TileDB arrays are designed to work seamlessly with pandas, NumPy, and R data frames. Wherever possible, TileDB uses Apache Arrow for zero-copy integration."
        backgroundImage={InteroperabilityBackground}
      >
        <div className="TDB-OpenSourcePage__MachineLearning__interoperability__icons">
          <img src={PandasIcon} alt="pandas" />
          <img src={NumpyIcon} alt="numpy" />
          <img src={RIcon} alt="r" />
          <img src={ArrowIcon} alt="arrow" />
        </div>
      </Card>
    </div>
  );
};

export default MachineLearning;
